export default [
    {
        to: {name: 'dashboard.index'},
        icon: 'mdi-home',
        text: 'Dashboard',
    },
    {
        to: {name: 'dashboard.company.show'},
        icon: 'mdi-diamond',
        text: 'Company',
    },
    {
        to: {name: 'dashboard.consultations.index'},
        icon: 'mdi-account-multiple',
        text: 'Consultations',
    },
    {
        to: {name: 'dashboard.assessments.index'},
        icon: 'mdi-account-multiple',
        text: 'Assessments',
    },
    {
        to: {name: 'dashboard.chats.index'},
        icon: 'mdi-message',
        text: 'chats',
    },
    {
        to: {name: 'dashboard.billing'},
        icon: 'mdi-credit-card',
        text: 'Billing',
    },
    {
        to: {name: 'dashboard.billing.select-plan'},
        icon: 'mdi-credit-card',
        text: 'Select Plan',
    },
]
